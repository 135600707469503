<template>
  <Form
    method="put"
    :action="`/presets/email-settings/${id}`"
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    class="pb-24"
    @success="handleSuccess"
  >
    <section class="flex gap-4 my-16">
      <div class="flex flex-wrap w-1/2 gap-4">
        <h2 class="w-full text-2xl font-normal font-poppins">
          Invoice Emailing
        </h2>
        <Field
          class="w-full"
          name="recipients"
          placeholder="jane@pfizer.com"
          label="Invoice Email Recipient(s):"
          component="textarea"
        />
        <Field
          class="w-full"
          name="subject"
          placeholder="Invoice"
          label="Default Email Subject Line"
        />
      </div>
      <div class="flex flex-wrap w-1/2 gap-4 mt-12">
        <Field
          class="w-full"
          name="message"
          placeholder="Test message"
          label="Default Invoice Email Message"
          component="textarea"
        />
      </div>
    </section>
    <Button variant="primary" class="w-64 font-bold font-poppins mb-4">
      Save Changes
    </Button>
  </Form>
</template>

<script>
/* eslint-disable no-useless-escape */
import { ref, onMounted } from 'vue';
import { object, string } from 'yup';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const initialValues = ref({});

    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/email-settings/${props.id}`,
      });
      initialValues.value = data;
    });

    const validationSchema = object({
      recipients: string()
        .transform((value) => value.replace(' ', ';'))
        .matches(
          /^(([A-Za-z0-9._%+-]+@{1}[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,4}),?)+$/,
          'Recipients must be a list of valid email addresses, separated by commas and no spaces.'
        )
        .required(),
      subject: string().required(),
      message: string().required(),
    });

    function handleSuccess(data) {
      toast.show(data.message);
    }

    return {
      validationSchema,
      initialValues,
      handleSuccess,
    };
  },
};
</script>
