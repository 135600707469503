<template>
  <Form
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    method="put"
    :action="`/presets/signature/${id}`"
  >
    <section class="flex gap-4 my-16">
      <div class="flex flex-wrap w-1/2 gap-4">
        <h2 class="w-full text-2xl font-normal font-poppins">
          Approval Signature
        </h2>
        <Field
          class="w-full"
          name="approverName"
          placeholder="Jane Doe"
          label="Name of Approver:"
          @update:modelValue="getUpdatedApproverName"
        />
        <Button
          v-show="!showUploadSignature"
          variant="secondary"
          class="w-56 mt-2"
          @click="showUploadSignature = !showUploadSignature"
          type="button"
          >Replace Signature</Button
        >
        <Button
          variant="primary"
          class="w-56 mt-2 font-bold font-poppins"
          @click="updateName"
          >Update Approver Name</Button
        >
      </div>
      <div class="flex flex-wrap w-1/2 gap-4 mt-12">
        <div class="w-3/4">
          <span class="block font-semibold font-poppins">
            Signature Preview:
          </span>
          <img
            v-if="!signatureBase64 && initialValues"
            class="mt-2 w-32"
            :src="initialValues.url"
          />
          <img
            v-if="signatureBase64"
            class="mt-2 signature w-32"
            :src="signatureBase64"
          />
        </div>
      </div>
    </section>
    <section v-show="showUploadSignature">
      <div class="h-20 w-full bg-gray-200 flex gap-4">
        <div class="flex flex-col justify-center flex-wrap w-1/2 gap-4 m-4">
          <div>
            <h1>Change Signature</h1>
          </div>
          <div class="flex flex-wrap">
            <div class="w-96">
              <input
                class="hidden"
                type="file"
                id="file"
                @change="getNewSignature"
              />
              <label
                for="file"
                class="cursor-pointer block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:text-gray-700 focus:border-blue-600 focus:outline-none"
              >
                <span v-if="newSignature && newSignature.name">
                  {{ newSignature.name }}
                </span>
                <span v-if="!newSignature"> Select File </span>
              </label>
            </div>
            <Button
              style="border-radius: 0px !important"
              :variant="uploadedSignature ? 'primary' : 'gray'"
              :disabled="!uploadedSignature"
              type="button"
              @click="uploadSignature"
            >
              <div class="flex flex-wrap">
                <span class="mr-2">Preview</span>
              </div>
            </Button>
          </div>
        </div>

        <div class="flex flex-wrap justify-center w-1/2 gap-4">
          <Button
            :variant="previewSignature ? 'primary' : 'gray'"
            :disabled="!previewSignature"
            class="w-56 my-4"
            @click.prevent="saveChanges"
          >
            Save Changes
          </Button>
        </div>
      </div>
    </section>
  </Form>
</template>

<script>
import { ref, onMounted } from 'vue';
import { object, string } from 'yup';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const toast = useToast();
    const store = useStore();
    const showUploadSignature = ref(false);
    const newSignature = ref('');
    const previewSignature = ref(false);
    const signatureBase64 = ref('');
    const uploadedSignature = ref(false);
    const initialValues = ref(null);
    const approverName = ref('');
    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/signature/${props.id}`,
      });
      initialValues.value = data;
    });

    const validationSchema = object({
      approverName: string().required(),
    });

    function getNewSignature(event) {
      newSignature.value = event.target.files[0];
      uploadedSignature.value = true;
    }

    function uploadSignature() {
      previewSignature.value = true;
      createBase64Image(newSignature.value);
    }

    function createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        signatureBase64.value = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    }

    async function saveChanges() {
      const formData = new FormData();
      formData.append('approverName', initialValues.value.approverName);
      formData.append('files', newSignature.value);
      const { message } = await store.dispatch('put', {
        action: `/presets/signature/${initialValues.value.id}`,
        formData,
      });
      toast.show(message);
      showUploadSignature.value = false;
    }

    async function updateName(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append('approverName', approverName.value);

      const { message } = await store.dispatch('put', {
        action: `/presets/signature/${initialValues.value.id}`,
        formData,
      });
      toast.show(message);
    }
    function getUpdatedApproverName(data){
      approverName.value = data;
    }

    return {
      validationSchema,
      initialValues,
      showUploadSignature,
      uploadSignature,
      getNewSignature,
      saveChanges,
      previewSignature,
      createBase64Image,
      signatureBase64,
      newSignature,
      uploadedSignature,
      updateName,
      getUpdatedApproverName
    };
  },
};
</script>
