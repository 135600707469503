<template>
  <Form
    method="put"
    :action="`/presets/contacts/${id}`"
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    @success="handleSuccess"
  >
    <section class="my-16">
      <h2 class="text-2xl font-bold font-poppins">
        <slot name="title"> CSM Mailing and Contact Information: </slot>
      </h2>
      <div
        class="grid grid-cols-3 gap-4 mt-10 mb-6 font-semibold w-7/8 max-w-screen-2xl font-poppins"
      >
        <Field
          name="companyName"
          label="Company Name"
          placeholder="CSM Group"
        />
        <div class="col-span-3" />
        <Field name="street" label="Street" placeholder="123 Street" />
        <Field name="suite" label="Suite" placeholder="Ste A" />
        <Field name="city" label="City" placeholder="Kalamazoo" />
        <Field name="zipcode" label="ZIP Code" placeholder="49300" />
        <Field name="state" label="State" placeholder="Michigan" />
        <Field name="country" label="Country" placeholder="USA" />
        <div class="col-span-3" />
        <Field
          name="emailAddress"
          label="Contact Email"
          placeholder="user@csmgroup.com"
        />
        <Field name="website" label="Website" placeholder="csmgroup.com" />
        <Field
          name="projectCoordinator"
          label="Accounts Contact"
          placeholder="Jane Doe"
        />
        <Field
          name="phoneNumber"
          label="Phone Number"
          placeholder="6165551212"
        />
        <Field name="faxNumber" label="Fax Number" placeholder="6165551212" />
      </div>
    </section>
    <Button variant="primary" class="w-64 mb-4 font-bold font-poppins">
      Save Changes
    </Button>
  </Form>
</template>

<script>
import { ref, onMounted } from 'vue';
import { object, string } from 'yup';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const store = useStore();
    const initialValues = ref({});
    const toast = useToast();

    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/contacts/${props.id}`,
      });
      initialValues.value = data;
    });

    const validationSchema = object({
      companyName: string().required(),
      street: string().required(),
      suite: string().required(),
      city: string().required(),
      zipcode: string().required(),
      state: string().required(),
      country: string().required(),
      emailAddress: string().required(),
      phoneNumber: string().required(),
      faxNumber: string().required(),
      website: string().optional(),
      projectCoordinator: string().optional(),
    });

    function handleSuccess(data){
      toast.show(data.message);
    }
    return {
      validationSchema,
      initialValues,
      handleSuccess
    };
  },
};
</script>
