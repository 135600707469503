<template>
  <div class="flex flex-wrap w-1/2 gap-4">
    <Form
      id="invoice-terms"
      method="put"
      :action="`/presets/invoice-terms/${id}`"
      :initial-values="initialValues"
      :validation-schema="validationSchema"
      class="w-full flex flex-wrap gap-4"
    >
      <h2 class="w-full text-2xl font-normal font-poppins">Invoice Terms</h2>
      <Field
        class="w-full"
        label="Invoice Notes"
        placeholder="Invoice Note"
        name="notes"
        component="textarea"
      />
    </Form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { object, string } from 'yup';
import { useStore } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const store = useStore();
    const initialValues = ref({});

    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/invoice-terms/${props.id}`,
      });
      initialValues.value = data;
    });

    const validationSchema = object({
      notes: string().required(),
    });

    return {
      validationSchema,
      initialValues,
    };
  },
};
</script>
