<template>
  <div class="h-full min-h-full px-4 pt-16 bg-white lg:px-16">
    <div class="flex justify-between">
      <h1 class="text-4xl font-normal font-poppins">Presets</h1>
    </div>
    <p class="mt-6 font-poppins">
      Manage your default company information, address, and additional details
      that appear on each invoice.
    </p>
    <!-- CSM Info -->
    <ContactForm :id="1" />
    <!-- Pfizer Info -->
    <ContactForm :id="2">
      <template #title> Pfizer Mailing and Contact Information: </template>
    </ContactForm>
    <section class="flex gap-6 my-16">
      <PaymentTermsForms />
      <InvoiceTermsForm />
    </section>
    <Button
      variant="primary"
      class="w-64 mb-12 font-bold font-poppins"
      @click.prevent="handleSubmit"
    >
      Save Changes
    </Button>
    <SignatureForm />
    <InvoiceEmailFrom />
  </div>
</template>

<script>
import ContactForm from '@/components/forms/ContactForm.vue';
import PaymentTermsForms from '@/components/forms/PaymentTermsForms.vue';
import InvoiceTermsForm from '@/components/forms/InvoiceTermsForm.vue';
import SignatureForm from '@/components/forms/SignatureForm.vue';
import InvoiceEmailFrom from '@/components/forms/InvoiceEmailForm.vue';
import { useFormInvoke } from '@/helpers/composables';

export default {
  components: {
    ContactForm,
    PaymentTermsForms,
    InvoiceTermsForm,
    SignatureForm,
    InvoiceEmailFrom,
  },
  setup() {
    const { submit: submitPaymentTerms } = useFormInvoke('payment-terms');
    const { submit: submitInvoiceTerms } = useFormInvoke('invoice-terms');

    function handleSubmit() {
      submitPaymentTerms();
      submitInvoiceTerms();
    }

    return {
      handleSubmit,
    };
  },
};
</script>
