<template>
  <div class="flex flex-wrap w-1/2 gap-4">
    <Form
      id="payment-terms"
      method="put"
      :action="`/presets/payment-terms/${id}`"
      :initial-values="initialValues"
      :validation-schema="validationSchema"
      class="w-full flex flex-wrap gap-4"
      @success="handleSuccess"
    >
      <h2 class="w-full text-2xl font-normal font-poppins">Billing Information</h2>
      <Field
        class="w-full"
        label="Checks Payable To:"
        placeholder="CSM Group"
        name="payableTo"
      />
      <Field
        class="w-full"
        label="Payment Terms:"
        placeholder="Net 90"
        name="dueDate"
      />
    </Form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { object, string } from 'yup';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: Number,
      default: () => 1,
    },
  },
  setup(props) {
    const store = useStore();
    const initialValues = ref({});
    const toast = useToast();

    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/payment-terms/${props.id}`,
      });
      initialValues.value = data;
    });

    const validationSchema = object({
      payableTo: string().required(),
      dueDate: string().required(),
    });

    function handleSuccess(data){
      toast.show(data.message);
    }

    return {
      validationSchema,
      initialValues,
      handleSuccess
    };
  },
};
</script>
